var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import Iconify from '../../components/iconify';
import { useSettingsContext } from '../../components/settings';
import usePermission from '../../hooks/usePermission';
import { useLocales } from '../../locales';
import { PATH_DASHBOARD } from '../../routes/paths';
import { BooksCashDeskPaymentsTable } from '../../sections/@dashboard/booksCashDeskPayments/list';
export default function BooksCashDeskPaymentList() {
    var translate = useLocales().translate;
    var themeStretch = useSettingsContext().themeStretch;
    var _a = usePermission(), isSuperAdmin = _a.isSuperAdmin, hasPermissionCreateCashRegister = _a.hasPermissionCreateCashRegister;
    // check current user permissions
    var isAllowedToCreateGeneralCashPayment = isSuperAdmin || hasPermissionCreateCashRegister;
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { children: _jsx("title", { children: "".concat(translate('Books Cash Desk Payments')) }) }), _jsxs(Container, __assign({ maxWidth: themeStretch ? false : 'lg' }, { children: [_jsx(CustomBreadcrumbs, { heading: "Books Cash Desk Payments", links: [{ name: 'General Cash Register' }], action: isAllowedToCreateGeneralCashPayment && (_jsx(Button, __assign({ component: RouterLink, to: PATH_DASHBOARD.books.cashDeskPayments.new, variant: "contained", startIcon: _jsx(Iconify, { icon: "eva:plus-fill" }) }, { children: "Create New" }))) }), _jsx(BooksCashDeskPaymentsTable, {})] }))] }));
}
